<template>
  <FadeIn 
  v-if="!quotePage && !isOverFooter" 
  class="fixed bottom-4 right-4 z-[9] block shadow-lg md:bottom-5 md:right-5"
  >
    <ElementButton
    class="block rounded-md bg-[#4d5ceb] px-5 py-2.5 text-white shadow-lg cursor-pointer transition-all duration-100 ease-in hover:bg-[#3645d0] z-[999]"
    url="/pricing" 
    type="primary"
    :arrow="true"
    :index="0"
    >
      Get a quote
    </ElementButton>
  </FadeIn>
</template>

<script setup>
const router = useRouter()
const quotePage = ref(false)
const scrollY = ref(0)
const footerTop = ref(0)
const windowHeight = ref(0)
const isOverFooter = ref(false)

if (router.currentRoute.value.name === 'pricing') {
  quotePage.value = true
}

watch(router.currentRoute, (newValue) => {
  if (newValue.name === 'pricing') {
    quotePage.value = true
  } else {
    quotePage.value = false
  }
})

const updatePositions = () => {
  scrollY.value = window.scrollY
  windowHeight.value = window.innerHeight  
  const footer = document.querySelector('.footer')
  const cta = document.querySelector('.cta-bottom')
  if (footer && cta) {
    footerTop.value = cta.getBoundingClientRect().top
    isOverFooter.value = footerTop.value < windowHeight.value - 80
  } else if (footer) {
    footerTop.value = footer.getBoundingClientRect().top
    isOverFooter.value = footerTop.value < windowHeight.value - 80
  }
}

onMounted(() => {
  updatePositions()
  window.addEventListener('scroll', updatePositions)
  window.addEventListener('resize', updatePositions)
})

onUnmounted(() => {
  window.removeEventListener('scroll', updatePositions)
  window.removeEventListener('resize', updatePositions)
})
</script>